import { API_URL } from './constants';
import {
  FormLogicCreateRequest,
  FormLogicCreateResponse,
  FormLogicDeleteRequest,
  FormLogicDeleteResponse,
  FormLogicGetRequest,
  FormLogicGetResponse,
  FormLogicListRequest,
  FormLogicListResponse,
  FormLogicRuleCreateRequest,
  FormLogicRuleCreateResponse,
  FormLogicRuleDeleteRequest,
  FormLogicRuleDeleteResponse,
  FormLogicRuleUpdateRequest,
  FormLogicRuleUpdateResponse,
  FormLogicUpdateRequest,
  FormLogicUpdateResponse,
} from './types/form-logic';

export async function apiFormsLogicList(
  token: string,
  body: FormLogicListRequest,
): Promise<[FormLogicListResponse, number]> {
  const url = new URL(`${API_URL}/admin/form-logic/list`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as FormLogicListResponse, response.status];
}

export async function apiFormsLogicDelete(
  token: string,
  body: FormLogicDeleteRequest,
): Promise<[FormLogicDeleteResponse, number]> {
  const url = new URL(`${API_URL}/admin/form-logic/delete`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as FormLogicDeleteResponse, response.status];
}

export async function apiFormsLogicCreate(
  token: string,
  body: FormLogicCreateRequest,
): Promise<[FormLogicCreateResponse, number]> {
  const url = new URL(`${API_URL}/admin/form-logic/create`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as FormLogicCreateResponse, response.status];
}

export async function apiFormsLogicUpdate(
  token: string,
  body: FormLogicUpdateRequest,
): Promise<[FormLogicUpdateResponse, number]> {
  const url = new URL(`${API_URL}/admin/form-logic/update`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as FormLogicUpdateResponse, response.status];
}

export async function apiFormsLogicGet(
  token: string,
  body: FormLogicGetRequest,
): Promise<[FormLogicGetResponse, number]> {
  const url = new URL(`${API_URL}/admin/form-logic/get`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as FormLogicGetResponse, response.status];
}

export async function apiFormsLogicRuleCreate(
  token: string,
  body: FormLogicRuleCreateRequest,
): Promise<[FormLogicRuleCreateResponse, number]> {
  const url = new URL(`${API_URL}/admin/form-logic/create-form-logic-rule`)
    .href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [
    (await response.json()) as FormLogicRuleCreateResponse,
    response.status,
  ];
}

export async function apiFormsLogicRuleDelete(
  token: string,
  body: FormLogicRuleDeleteRequest,
): Promise<[FormLogicRuleDeleteResponse, number]> {
  const url = new URL(`${API_URL}/admin/form-logic/delete-form-logic-rule`)
    .href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [
    (await response.json()) as FormLogicRuleDeleteResponse,
    response.status,
  ];
}

export async function apiFormsLogicRuleUpdate(
  token: string,
  body: FormLogicRuleUpdateRequest,
): Promise<[FormLogicRuleUpdateResponse, number]> {
  const url = new URL(`${API_URL}/admin/form-logic/update-form-logic-rule`)
    .href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [
    (await response.json()) as FormLogicRuleUpdateResponse,
    response.status,
  ];
}
