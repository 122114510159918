import { API_URL } from './constants';
import {
  FormResultCreateRequest,
  FormResultCreateResponse,
  FormResultDeleteRequest,
  FormResultDeleteResponse,
  FormResultGetRequest,
  FormResultGetResponse,
  FormResultListRequest,
  FormResultListResponse,
  FormResultUpdateRequest,
  FormResultUpdateResponse,
} from './types/form-result';

export async function apiFormsResultList(
  token: string,
  body: FormResultListRequest,
): Promise<[FormResultListResponse, number]> {
  const url = new URL(`${API_URL}/admin/form-results/list`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as FormResultListResponse, response.status];
}

export async function apiFormsResultDelete(
  token: string,
  body: FormResultDeleteRequest,
): Promise<[FormResultDeleteResponse, number]> {
  const url = new URL(`${API_URL}/admin/form-results/delete`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as FormResultDeleteResponse, response.status];
}

export async function apiFormsResultCreate(
  token: string,
  body: FormResultCreateRequest,
): Promise<[FormResultCreateResponse, number]> {
  const url = new URL(`${API_URL}/admin/form-results/create`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as FormResultCreateResponse, response.status];
}

export async function apiFormsResultUpdate(
  token: string,
  body: FormResultUpdateRequest,
): Promise<[FormResultUpdateResponse, number]> {
  const url = new URL(`${API_URL}/admin/form-results/update`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as FormResultUpdateResponse, response.status];
}

export async function apiFormsResultGet(
  token: string,
  body: FormResultGetRequest,
): Promise<[FormResultGetResponse, number]> {
  const url = new URL(`${API_URL}/admin/form-results/get`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as FormResultGetResponse, response.status];
}
