import { useForm } from 'antd/es/form/Form';
import { useNavigate, useParams } from 'react-router';
import { selectUserState } from '../../store/selectors/user';
import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import {
  Button,
  Card,
  ColorPicker,
  Form,
  Input,
  InputNumber,
  message,
} from 'antd';
import {
  apiFormsDeleteTemplate,
  apiFormsGetTemplate,
  apiFormsUpdateTemplate,
} from '../../services/api/forms';
import { RouterPaths } from '../../router/paths';
import TextArea from 'antd/es/input/TextArea';
import { ApiFormsUpdateTemplateRequestDto } from '../../services/api/types/forms';
import { LengthConstants } from '../../services/api/types';

interface FormValues {
  name: string;
  color: string;
  category: string;
  description: string;
  order: number;
}

function SingleTemplatePage() {
  const { token } = useSelector(selectUserState);
  const { templateId } = useParams();
  const [form] = useForm<FormValues>();
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  async function fetchData() {
    if (token && templateId) {
      try {
        setLoading(true);
        const [res] = await apiFormsGetTemplate(token, { id: templateId });
        if (res.template) {
          form.resetFields();

          form.setFieldsValue({
            name: res.template.name,
            color: res.template.color?.replace('#', ''),
            category: res.template.category,
            description: res.template.description,
            order: res.template.order,
          });
        }
      } catch (e) {
        console.error(e);
        message.error('Failed to fetch template');
        navigate(RouterPaths.TEMPLATES);
      } finally {
        setLoading(false);
      }
    }
  }

  async function deleteTemplate() {
    if (token && templateId) {
      try {
        setLoading(true);
        const [res] = await apiFormsDeleteTemplate(token, { id: templateId });
        if (res.template) {
          message.success('Template deleted');
          navigate(RouterPaths.TEMPLATES);
        } else {
          message.error('Failed to delete template');
        }
      } catch (e) {
        console.error(e);
        message.error('Failed to delete template');
      } finally {
        setLoading(false);
      }
    }
  }

  async function onSubmit(values: FormValues) {
    if (token && templateId) {
      const updateBody: ApiFormsUpdateTemplateRequestDto = {
        formTemplateId: templateId,
        ...values,
        color: `#${values.color}`,
      };

      try {
        setLoading(true);
        const [res] = await apiFormsUpdateTemplate(token, updateBody);
        if (res.template) {
          message.success('Template updated');
        } else {
          message.error('Failed to update template');
        }

        await fetchData();
      } catch (e) {
        console.error(e);
        message.error('Failed to update template');
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    if (token && templateId) {
      fetchData();
    }
  }, [token, templateId]);

  const isLoading = loading || !token || !templateId;

  return (
    <Card title="Template">
      <div style={{ display: 'flex', width: '100%', marginBottom: 45 }}>
        <Button danger type="primary" onClick={deleteTemplate}>
          Delete
        </Button>
      </div>
      <Form form={form} disabled={isLoading} onFinish={onSubmit}>
        <Form.Item
          label="Order"
          name="order"
          required
          rules={[
            {
              required: true,
              message: 'Order is required',
            },
            {
              type: 'number',
              min: 0,
              message: 'Order must be a positive number',
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          required
          rules={[
            {
              required: true,
              message: 'Name is required',
            },
            {
              type: 'string',
              max: LengthConstants.FORM_TEMPLATE_NAME,
              message: `Name must be less than ${LengthConstants.FORM_TEMPLATE_NAME} characters`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Color"
          name="color"
          required
          getValueFromEvent={(color) => {
            return color.toHex();
          }}
          rules={[
            {
              required: true,
              message: 'Color is required',
            },
          ]}
        >
          <ColorPicker />
        </Form.Item>
        <Form.Item
          label="Category"
          name="category"
          required
          rules={[
            {
              required: true,
              message: 'Category is required',
            },
            {
              type: 'string',
              max: LengthConstants.FORM_TEMPLATE_CATEGORY,
              message: `Category must be less than ${LengthConstants.FORM_TEMPLATE_CATEGORY} characters`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          required
          rules={[
            {
              required: true,
              message: 'Description is required',
            },
            {
              type: 'string',
              max: LengthConstants.FORM_TEMPLATE_DESCRIPTION,
              message: `Description must be less than ${LengthConstants.FORM_TEMPLATE_DESCRIPTION} characters`,
            },
          ]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Update
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default SingleTemplatePage;
