import { AgencyUserEntity } from './agency-users';
import { CNAMEDto } from './cname';
import { FormLogicEntity } from './form-logic';
import { FormResultEntity } from './form-result';
import { ImageEntity } from './images';
import { DesignType, InputType, StepEntity, ViewType } from './steps';
import { UserEntity } from './users';

export interface FormTemplateJSONData {
  requirePhone: boolean;
  requireAddress: boolean;
  requireBusinessName: boolean;
  additionalNotes: boolean;
  result: {
    title: string;
    description: string;
    imageAliasId: string | null;
  };
}

export interface FormTemplateStepJSONData {
  title: string;
  description: string;
  order: number;
  inputType: InputType;
  designType: DesignType;
  viewType: ViewType;
  inputLabel: string | null;
  inputLabelSecond: string | null;
  options: {
    order: number;
    title: string;
    imageAliasId: string | null;
  }[];
}

export interface FormTemplateStepEntity {
  id: number;
  aliasId: string;
  createdAt: string;
  modifiedAt: string;
  deletedAt: string | null;
  order: number;
  data: FormTemplateStepJSONData;
  formTemplate?: FormTemplateEntity;
  image?: ImageEntity | null;
}

export interface FormTemplateEntity {
  id: number;
  order: number;
  aliasId: string;
  createdAt: string;
  modifiedAt: string;
  deletedAt: string | null;
  name: string;
  color: string;
  category: string;
  description: string;
  image?: ImageEntity | null;
  formData: FormTemplateJSONData;
  steps?: FormTemplateStepEntity[];
}

export interface FormSettingsEntity {
  linkable: boolean;
  developmentMode: boolean;
  requirePhone: boolean;
  requireAddress: boolean;
  requireAdditionalNotes: boolean;
  requireBusinessName: boolean;
  ga4ID: string | null;
  calendlyUrl: string | null;
  calendlyEnabled: boolean;
  resultScreenRedirect: string | null;
  resultScreenButtonRedirect: string | null;
  facebookPixelId?: string | null;
  collectUTM: boolean;
  whatConvertsProfileId: string | null;
  googleAdsTagId: string | null;
  googleAdsDestinationId: string | null;
  tikTokPixelId: string | null;
  linkedInPartnerId: string | null;
  linkedInConversionId: string | null;
  googleTagManagerId: string | null;
  googleTagManagerConversionEvent: string | null;
  dateFormat: string;
}

export enum PopupType {
  SIMPLE = 'simple',
  TAKEOVER = 'takeover',
}

export interface PopupEntity {
  id: number;
  createdAt: string;
  modifiedAt: string;
  deletedAt: null;
  title: string;
  label: string;
  proceedText: string;
  cancelText: string;
  triggerTime: number;
  intentTrigger: boolean;
  type: PopupType;
}

export interface FormEntityTranslations {
  'first-name-input': string;
  'last-name-input': string;
  'email-input': string;
  'address-input': string;
  'city-input': string;
  'zip-input': string;
  'phone-number-input': string;
  'additional-notes-input': string;
  'disclaimer-title': string;
  'disclaimer-text': string;
  'phone-header-cta-label-1': string;
  'phone-header-cta-label-2': string;
  'result-phone-call': string;
  'result-starting-from': string;
  'result-starting-from-disclaimer': string;
  'result-below-phone': string;
  'business-name-input': string;
}

// Generated by https://quicktype.io

export interface FormNotificationEmail {
  id: number;
  createdAt: string;
  modifiedAt: string;
  deletedAt: null;
  notificationEmail: string;
}

export interface FormOrigin {
  id: number;
  createdAt: string;
  modifiedAt: string;
  deletedAt: null;
  origin: string;
}

export interface FormWebhookEntity {
  id: number;
  createdAt: string;
  modifiedAt: string;
  deletedAt: null;
  url: string;
}

export enum WorkspaceType {
  AGENCY = 'agency',
  PERSONAL = 'personal',
}

export enum WorkspaceUserRole {
  OWNER = 'owner',
  ADMIN = 'admin',
  EDITOR = 'editor',
  MEMBER = 'member',
}

export interface WorkspaceUserEntity {
  id: number;
  aliasId: string;
  createdAt: string;
  modifiedAt: string;
  deletedAt: string | null;
  role: WorkspaceUserRole;
  user: UserEntity;
  workspace: WorkspaceEntity;
}

export enum WorkspaceInvitationStatus {
  PENDING = 'pending',
  SENT = 'sent',
  COMPLETED = 'completed',
}

export interface WorkspaceInvitationEntity {
  id: number;
  aliasId: string;
  createdAt: string;
  modifiedAt: string;
  deletedAt: string | null;
  email: string;
  role: WorkspaceUserRole;
  status: WorkspaceInvitationStatus;
  invitedBy: UserEntity;
  workspace: WorkspaceEntity;
}

export interface WorkspaceEntity {
  id: number;
  aliasId: string;
  createdAt: string;
  modifiedAt: string;
  deletedAt: string | null;
  name: string;
  slug: string;
  type: WorkspaceType;
  parentWorkspace: WorkspaceEntity | null;
  childWorkspaces: WorkspaceEntity[];
  team: WorkspaceUserEntity[];
  invitations: WorkspaceInvitationEntity[];
  forms: FormEntity[];
  cnames: CNAMEDto[];
}

export interface FormStyleEntity {
  id: number;
  aliasId: string;
  createdAt: string;
  modifiedAt: string;
  deletedAt: string | null;
  backgroundColor: string;
  buttonBackgroundColor: string;
  buttonTextColor: string;
  buttonBorderRadius: number;
  inputBackgroundColor: string;
  inputTextColor: string;
  titleColor: string;
  logoImage: ImageEntity | null;
  disableBranding: boolean;
  iframeEmbedStyle: TapformIframeEmbedStyle;
}

export enum FormWidgetTheme {
  DARK = 'dark',
  LIGHT = 'light',
}

export interface FormWidgetEntity {
  id: number;
  aliasId: string;
  createdAt: string;
  modifiedAt: string;
  deletedAt: string | null;
  widgetTitle: string;
  widgetButtonText: string;
  widgetButtonBackgroundColor: string | null;
  widgetButtonTextColor: string | null;
  widgetLabel: string;
  defaultVisible: boolean;
  offsetBottom: number;
  hideOnBottom: boolean;
  hideShowOnScroll: boolean;
  widgetTheme: FormWidgetTheme;
  image: ImageEntity | null;
}

export interface FormEntity {
  id: number;
  aliasId: string;
  createdAt: string;
  modifiedAt: string;
  deletedAt: string | null;
  name: string;
  origin: string;
  slug: string;
  developmentMode: boolean;
  phoneNumber: string | null;
  translations: FormEntityTranslations;
  steps?: StepEntity[];
  settings?: FormSettingsEntity;
  notificationEmails?: FormNotificationEmail[];
  origins?: FormOrigin[];
  popups?: PopupEntity[];
  agencyUser?: AgencyUserEntity | null;
  webhooks: FormWebhookEntity[] | null;
  workspace: WorkspaceEntity;
  style: FormStyleEntity;
  widget: FormWidgetEntity;
  results?: FormResultEntity[];
  logic?: FormLogicEntity[];
}

export interface ApiFormsCreateWizardRequestBody {
  userId: number;
  form: {
    brandColor: string;
    logoImageId: number;
    name: string;
    origin: string;
    slug: string;
    widgetButtonText: string;
    widgetTitle: string;
    linkable: boolean;
    requirePhone: boolean;
    developmentMode: boolean;
    requireAddress: boolean;
    requireAdditionalNotes: boolean;
    createDefaultPopup: boolean;
    resultScreenButtonRedirect: string | null;
    resultScreenRedirect: string | null;
    phoneNumber?: string;
  };
  steps: {
    isFinalStep: boolean;
    order: number;
    submitButtonText: string;
    title: string;
    type: InputType | null;
    inputLabel: string | null;
    inputLabelSecond: string | null;
    nextQuestionIndex: number | null;
    options: {
      imageId: number | null;
      order: number;
      value: string;
      resultDescription?: string;
      resultTitle?: string;
      resultMinPrice?: number;
      resultImageId?: number;
      nextQuestionIndex: number | null;
    }[];
  }[];
}

export interface ApiFormsCreatePopupRequestBody {
  title: string;
  label: string;
  triggerTime: number;
  proceedText: string;
  cancelText: string;
  formId: number;
  intentTrigger?: boolean;
  imageId?: string | null;
  type: PopupType;
  backgroundColor?: string | null;
  textColor?: string | null;
  buttonBackgroundColor?: string | null;
  buttonTextColor?: string | null;
}

export interface ApiFormsCreatePopupResponse {
  data: {
    success: boolean;
  };
}

export interface ApiFormsDeletePopupRequestBody {
  popupId: number;
}

export interface ApiFormsDeletePopupResponse {
  data: {
    success: boolean;
  };
}

export interface ApiFormsListResponse {
  forms: FormEntity[];
}

export interface ApiFormsGetResponse {
  data: FormEntity;
}

export interface ApiFormsUpdateRequestBody {
  formId: number;
  name?: string;
  slug?: string;
  brandColor?: string;
  origin?: string;
  developmentMode?: boolean;
  requireAdditionalNotes?: boolean;
  requireBusinessName?: boolean;
  linkable?: boolean;
  requireAddress?: boolean;
  requirePhone?: boolean;
  phoneNumber?: string;
  translations?: FormEntityTranslations;
  origins?: string[];
  emails?: string[];
  ga4ID?: string | null;
  agencyUserId?: number | null;
  calendlyUrl?: string | null;
  calendlyEnabled?: boolean;
  webhooks?: string[];
  resultScreenButtonRedirect?: string | null;
  resultScreenRedirect?: string | null;
  facebookPixelId?: string | null;
  collectUTM?: boolean;
  whatConvertsProfileId?: string | null;
  googleAdsTagId?: string | null;
  googleAdsDestinationId?: string | null;
  tikTokPixelId?: string | null;
  linkedInPartnerId?: string | null;
  linkedInConversionId?: string | null;
  googleTagManagerId?: string | null;
  googleTagManagerConversionEvent?: string | null;
  dateFormat?: string;
}

export interface ApiFormsUpdateResponse {
  data: FormEntity;
}

export interface FormMetaDataEntity {
  id: number;
  aliasId: string;
  createdAt: string;
  modifiedAt: string;
  deletedAt: string | null;
  form?: FormEntity;
  seoTitle: string | null;
  seoDescription: string | null;
  seoImage?: ImageEntity | null;
  favicon?: ImageEntity | null;
}

export interface ApiFormsGetFormMetaDataRequestBody {
  formId: number;
}

export interface ApiFormsGetFormMetaDataResponse {
  formMetaData: FormMetaDataEntity;
}

export interface ApiFormsUpdateFormMetaDataRequestBody {
  formId: number;
  seoTitle?: string | null;
  seoDescription?: string | null;
  seoImageId?: number | null;
  faviconId?: string | null;
}

export interface ApiFormsUpdateFormMetaDataResponse {
  formMetaData: FormMetaDataEntity;
}

export interface TapformIframeEmbedStyle {
  width: number;
  height: number;
  widthType: 'px' | '%';
  heightType: 'px' | '%' | 'auto';
}

export interface ApiFormsUpdateFormStylesRequestBody {
  formId: string;
  backgroundColor?: string;
  buttonBackgroundColor?: string;
  buttonTextColor?: string;
  buttonBorderRadius?: number;
  inputBackgroundColor?: string;
  inputTextColor?: string;
  titleColor?: string;
  logoImageId?: string;
  disableBranding?: boolean;
  iframeEmbedStyle?: Partial<TapformIframeEmbedStyle>;
}

export interface ApiFormsUpdateFormStylesResponse extends FormStyleEntity {}

export interface ApiFormsUpdateFormWidgetRequestBody {
  formId: string;
  widgetTitle?: string;
  widgetButtonText?: string;
  widgetButtonBackgroundColor?: string | null;
  widgetButtonTextColor?: string | null;
  widgetLabel?: string;
  defaultVisible?: boolean;
  offsetBottom?: number;
  hideOnBottom?: boolean;
  hideShowOnScroll?: boolean;
  widgetTheme?: FormWidgetTheme;
  imageId?: string | null;
}

export interface ApiFormsUpdateFormWidgetResponse extends FormWidgetEntity {}

export interface ApiFormsDeleteRequest {
  formId: number;
}

export interface ApiFormsDeleteResponse {
  data: {
    success: boolean;
  };
}

export interface ApiFormsUploadTemplateRequestDto {
  googleSheetUrl: string;
}

export interface ApiFormsUploadTemplateResponseDto {
  template: FormTemplateEntity;
}

export interface ApiFormsListTemplatesRequestDto {
  pagination: {
    limit: number;
    offset: number;
  };
}

export interface ApiFormsListTemplatesResponseDto {
  templates: FormTemplateEntity[];
  pagination: {
    limit: number;
    offset: number;
    total: number;
  };
}

export interface ApiFormsGetTemplateRequestDto {
  id: string;
}

export interface ApiFormsGetTemplateResponseDto {
  template: FormTemplateEntity;
}

export interface ApiFormsDeleteTemplateRequestDto {
  id: string;
}

export interface ApiFormsDeleteTemplateResponseDto {
  template: FormTemplateEntity;
}

export interface ApiFormsUpdateTemplateRequestDto {
  formTemplateId: string;
  name?: string;
  color?: string;
  category?: string;
  description?: string;
  order?: number;
}

export interface ApiFormsUpdateTemplateResponseDto {
  template: FormTemplateEntity;
}
