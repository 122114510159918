/* eslint-disable @typescript-eslint/no-empty-interface */
import {
  ApiPaginationRequest,
  ApiPaginationResponse,
  CurrencyCode,
  SortingRequestDto,
  SortingResponseDto,
} from '.';

export enum SubscriptionCycle {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export enum SubscriptionStatus {
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
  TRIAL = 'trial',
  PAUSED = 'paused',
  EXPIRED = 'expired',
}

export enum CheckoutType {
  LINK = 'link',
  ON_SITE = 'on-site',
  LEMON_SQUEEZY_OVERLAY = 'lemon-squeezy-overlay',
}

export interface BillingSettingsDto {
  paymentWall: boolean;
  lemonSqueezyEmbed: boolean;
}

export interface InvoiceDto {
  id: string;

  name: string;

  priceCent: number;

  createdAt: string;

  currency: CurrencyCode;
}

export interface SubscriptionLemonSqueezyDataDto {
  monthlyProductId: string | null;
  monthlyProductVariantId: string | null;
  yearlyProductId: string | null;
  yearlyProductVariantId: string | null;
}

export interface SubscriptionDto {
  id: string;

  type: string;

  name: string;

  description: string;

  lemonSqueezyData: SubscriptionLemonSqueezyDataDto;

  monthlyPriceCent: number;

  yearlyPriceCent: number;
}

export interface UserSubscriptionDto {
  id: string;

  createdAt: string;

  startsOn: string;

  endsOn: string;

  status: SubscriptionStatus;

  cycle: SubscriptionCycle;

  subscription: SubscriptionDto | null;

  priceOverrideCent: number | null;
}

export interface UserBillingAccountDto {
  id: string;

  createdAt: string;

  modifiedAt: string | null;

  deletedAt: string | null;

  provider: string;

  externalId: string | null;

  externalSubscriptionId: string | null;

  active: boolean;

  defaultAccount: boolean;
}

export interface CancelSubscriptionRequest {
  userId: string;
}

export interface CancelSubscriptionResponse {
  userSubscription: UserSubscriptionDto;
  success: boolean;
}

export interface ChangeSubscriptionRequest {
  userId: string;
  subscriptionType?: string;
  cycle?: SubscriptionCycle;
  status?: SubscriptionStatus;
  priceOverrideCent?: number | null;
}

export interface CheckoutLinkData {
  expiresAt: string | null;
  url: string;
}

export interface CheckoutDto {
  id: string;
  type: CheckoutType;
  linkData: CheckoutLinkData | null;
}

export interface ChangeSubscriptionResponse {
  checkout: CheckoutDto | null;
  success: boolean;
}

export interface DownloadInvoiceRequest {
  userId: string;
  invoiceId: string;
}

export interface DownloadInvoiceResponse {
  downloadUrl: string;
}

export interface GetActiveSubscriptionRequest {
  userId: string;
}

export interface GetActiveSubscriptionResponse {
  userSubscription: UserSubscriptionDto;
}

export interface ListAvailableSubscriptionsRequest {
  userId?: string;
}

export interface ListAvailableSubscriptionsResponse {
  subscriptions: SubscriptionDto[];
}

export interface ListInvoicesRequest {
  userId: string;
  pagination?: ApiPaginationRequest;
  sorting?: SortingRequestDto;
}

export interface ListInvoicesResponse {
  invoices: InvoiceDto[];
  pagination: ApiPaginationResponse;
  sorting: SortingResponseDto;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ListUserBillingAccountsRequest {}

export interface ListUserBillingAccountsResponse {
  userBillingAccounts: UserBillingAccountDto[];
}

export interface CreateCheckoutRequest extends ChangeSubscriptionRequest {}

export interface CreateCheckoutResponse extends ChangeSubscriptionResponse {}

export interface UpdateBillingSettingsRequest {
  paymentWall?: boolean;
  lemonSqueezyEmbed?: boolean;
}

export interface UpdateBillingSettingsResponse {
  billingSettings: BillingSettingsDto;
}

export interface GetBillingSettingsRequest {
  //
}

export interface GetBillingSettingsResponse {
  billingSettings: BillingSettingsDto;
}

export interface UpdateSubscriptionLemonSqueezyDataDto {
  monthlyProductId?: string | null;
  monthlyProductVariantId?: string | null;
  yearlyProductId?: string | null;
  yearlyProductVariantId?: string | null;
}

export interface AdminUpdateBillingSubscriptionRequest {
  type: string;
  name?: string;
  description?: string;
  monthlyPriceCent?: number;
  yearlyPriceCent?: number;
  lemonSqueezy?: UpdateSubscriptionLemonSqueezyDataDto;
}

export interface AdminUpdateBillingSubscriptionResponse {
  billingSubscription: SubscriptionDto;
}

export interface SyncUserSubscriptionWithBillingAccountRequest {
  userId: string;
}

export interface SyncUserSubscriptionWithBillingAccountResponse {
  success: boolean;
}

export interface UpdateUserBillingAccountRequest {
  userBillingAccountId: string;
  externalId?: string;
  externalSubscriptionId?: string;
  default?: boolean;
}

export interface UpdateUserBillingAccountResponse {
  userBillingAccount: UserBillingAccountDto;
}
