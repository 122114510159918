import { FormResultEntity } from './form-result';
import { FormEntity } from './forms';
import { OptionEntity } from './options';
import { InputEntity, StepEntity } from './steps';

export enum LogicType {
  IF = 'if',
  ALWAYS = 'always',
}

export enum LogicThenType {
  GO_TO = 'go-to',
  ADD = '+',
  SUBTRACT = '-',
  MULTIPLY = '*',
  DIVIDE = '/',
}

export enum NextLogicRuleType {
  AND = 'and',
  OR = 'or',
}

export enum LogicRuleType {
  IS = 'is',
  IS_NOT = 'is-not',
}

export interface FormLogicRuleEntity {
  id: number;
  aliasId: string;
  createdAt: string;
  modifiedAt: string;
  deletedAt: string | null;
  order: number;
  formLogic?: FormLogicEntity | null;
  input?: InputEntity;
  type: LogicRuleType;
  option?: OptionEntity | null;
  value: string | null;
  nextRuleType: NextLogicRuleType;
  nextRule?: FormLogicRuleEntity | null;
  previousRules?: FormLogicRuleEntity[];
}

export interface FormLogicEntity {
  id: number;
  aliasId: string;
  createdAt: string;
  modifiedAt: string;
  deletedAt: string | null;
  form?: FormEntity;
  step?: StepEntity;
  type: LogicType;
  rules?: FormLogicRuleEntity[];
  thenType: LogicThenType;
  thenValue?: number;
  thenGoToStep?: StepEntity | null;
  thenGoToResult?: FormResultEntity | null;
}

export interface FormLogicCreateRequest {
  formId: string;
  stepId: string;
  type?: LogicType;
  thenType?: LogicThenType;
  thenValue?: number | null;
  thenGoToStepId?: string | null;
  thenGoToResultId?: string | null;
  rules: Omit<FormLogicRuleCreateRequest, 'formLogicId'>[];
}

export interface FormLogicCreateResponse {
  logic: FormLogicEntity;
}

export interface FormLogicDeleteRequest {
  ids: string[];
}

export interface FormLogicDeleteResponse {
  logic: FormLogicEntity[];
}

export interface FormLogicUpdateRequest {
  formLogicId: string;
  type?: LogicType;
  thenType?: LogicThenType;
  thenValue?: number | null;
  thenGoToStepId?: string | null;
  thenGoToResultId?: string | null;
}

export interface FormLogicUpdateResponse {
  logic: FormLogicEntity;
}

export interface FormLogicListRequest {
  formId: string;
}

export interface FormLogicListResponse {
  logic: FormLogicEntity[];
}

export interface FormLogicGetRequest {
  formLogicId: string;
}

export interface FormLogicGetResponse {
  logic: FormLogicEntity;
}

export interface FormLogicRuleCreateRequest {
  formLogicId: string;
  inputId: string;
  type?: LogicRuleType;
  optionId?: string | null;
  value?: string | null;
  previousRuleType?: NextLogicRuleType;
}

export interface FormLogicRuleCreateResponse {
  rule: FormLogicRuleEntity;
}

export interface FormLogicRuleDeleteRequest {
  ids: string[];
}

export interface FormLogicRuleDeleteResponse {
  rules: FormLogicRuleEntity[];
}

export interface FormLogicRuleUpdateRequest {
  formLogicRuleId: string;
  inputId?: string;
  type?: LogicRuleType;
  optionId?: string | null;
  value?: string | null;
  nextRuleType?: NextLogicRuleType;
}

export interface FormLogicRuleUpdateResponse {
  rule: FormLogicRuleEntity;
}
