import { Form, FormItemProps, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { selectUserState } from '../../store/selectors/user';
import { useSelector } from 'react-redux';
import { apiFormsGetByAliasId } from '../../services/api/forms';
import { ApiFormsGetResponse } from '../../services/api/types/forms';
import { DefaultOptionType } from 'antd/es/select';
import { StepEntity } from '../../services/api/types/steps';

interface StepSelectorProps {
  formId: string;
  style?: React.CSSProperties;
  name: FormItemProps['name'];
  label?: FormItemProps['label'];
  disabled?: boolean;
  required?: boolean;
  initialValue?: string;
  onChange?: (
    value: any,
    option: DefaultOptionType | DefaultOptionType[],
  ) => void;
  steps?: StepEntity[];
}

function StepSelector({
  name,
  label,
  disabled,
  style,
  formId,
  required = true,
  initialValue,
  onChange,
  steps,
}: StepSelectorProps) {
  const { token } = useSelector(selectUserState);
  const [responseGetForm, setResponseGetForm] =
    useState<ApiFormsGetResponse | null>(null);

  async function loadData() {
    if (token && formId) {
      const [res] = await apiFormsGetByAliasId(token, formId);

      if (res?.data) {
        setResponseGetForm(res);
      }
    }
  }

  useEffect(() => {
    if (!steps) {
      loadData();
    }
  }, [formId, token, steps]);

  const toUse = steps ?? responseGetForm?.data?.steps ?? [];

  return (
    <Form.Item
      name={name}
      label={label}
      style={style}
      rules={[{ required, message: 'Please select a step!' }]}
      initialValue={initialValue}
    >
      <Select
        disabled={disabled || (responseGetForm === null && !steps)}
        options={toUse.map<DefaultOptionType>((step) => ({
          value: step.aliasId,
          label: step.title,
        }))}
        onChange={onChange}
      />
    </Form.Item>
  );
}

export default StepSelector;
