import { Button, Card, Input, message, Table, TableProps } from 'antd';
import React, { useEffect } from 'react';
import {
  ApiFormsListTemplatesResponseDto,
  FormTemplateEntity,
} from '../../services/api/types/forms';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import {
  apiFormsDeleteTemplate,
  apiFormsListTemplates,
  apiFormsUploadTemplate,
} from '../../services/api/forms';
import Image from '../../components/Image';
import { Link } from 'react-router-dom';
import { RouterPaths } from '../../router/paths';
import { MinusOutlined } from '@ant-design/icons';

interface TemplateRow extends FormTemplateEntity {
  key: string;
}

function TemplatesPage() {
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState<{
    limit: number;
    offset: number;
  }>({ limit: 10, offset: 0 });
  const { token } = useSelector(selectUserState);
  const [googleSheetUrl, setGoogleSheetUrl] = React.useState('');
  const [response, setResponse] =
    React.useState<ApiFormsListTemplatesResponseDto | null>(null);

  async function fetchData() {
    setLoading(true);
    try {
      if (token) {
        const [response] = await apiFormsListTemplates(token, { pagination });
        if (response?.templates) {
          setResponse(response);
        } else {
          message.error('Failed to fetch templates');
        }
      }
    } catch (error) {
      console.error(error);
      message.error('Failed to fetch templates');
    } finally {
      setLoading(false);
    }
  }

  async function deleteTemplate(id: string) {
    if (token) {
      try {
        setLoading(true);
        const [res] = await apiFormsDeleteTemplate(token, { id });
        if (res.template) {
          message.success('Template deleted');
        } else {
          message.error('Failed to delete template');
        }

        await fetchData();
      } catch (e) {
        console.error(e);
        message.error('Failed to delete template');
      } finally {
        setLoading(false);
      }
    }
  }

  async function createTemplate() {
    if (token && googleSheetUrl?.length) {
      if (!googleSheetUrl.includes('https://')) {
        message.error('Invalid Google Sheet URL');
        return;
      }
      setLoading(true);
      try {
        const [res] = await apiFormsUploadTemplate(token, { googleSheetUrl });

        if (res?.template) {
          message.success('Template created');
          setGoogleSheetUrl('');
          await fetchData();
        } else {
          message.error('Failed to create template');
        }
      } catch (error) {
        console.error(error);
        message.error('Failed to create template');
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, [token, pagination]);

  const columns: TableProps<TemplateRow>['columns'] = [
    {
      title: 'Order',
      dataIndex: 'order',
      key: 'order',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (v, record) => (
        <Link
          to={RouterPaths.SINGLE_TEMPLATE.replace(
            ':templateId',
            record.aliasId,
          )}
        >
          {v}
        </Link>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      render: (v) => (
        <div
          style={{
            backgroundColor: v,
            width: 25,
            height: 25,
            borderRadius: '50%',
          }}
        />
      ),
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (v, record) =>
        record.image?.id ? <Image id={record.image?.id} /> : 'No image',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (v, record) => (
        <Button
          type="primary"
          danger
          icon={<MinusOutlined />}
          onClick={async () => {
            await deleteTemplate(record.aliasId);
          }}
        />
      ),
    },
  ];

  const dataSource =
    response?.templates?.map((template) => ({
      key: template.id.toString(),
      ...template,
    })) || ([] as TemplateRow[]);

  const isLoading = !response?.templates || loading;

  return (
    <Card title="Templates">
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 45 }}>
        <Input
          placeholder="Google Sheet URL"
          value={googleSheetUrl}
          onChange={(e) => {
            setGoogleSheetUrl(e.target.value);
          }}
          disabled={isLoading}
          style={{ marginRight: 15 }}
        />
        <Button
          disabled={isLoading}
          type="primary"
          htmlType="button"
          onClick={createTemplate}
        >
          Create
        </Button>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          total: response?.pagination.total || 0,
          pageSize: pagination.limit,
        }}
        loading={isLoading}
        onChange={(pagination) => {
          setPagination({
            limit: pagination.pageSize || 10,
            offset:
              (pagination.current || 1) * (pagination.pageSize || 10) -
              (pagination.pageSize || 10),
          });
        }}
      />
    </Card>
  );
}

export default TemplatesPage;
