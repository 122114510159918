import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
} from 'antd';
import { useNavigate, useParams } from 'react-router';
import React, { useEffect, useState } from 'react';
import { RouterPaths } from '../../router/paths';
import {
  FormLogicEntity,
  FormLogicListResponse,
  FormLogicRuleEntity,
  LogicRuleType,
  LogicThenType,
  LogicType,
  NextLogicRuleType,
} from '../../services/api/types/form-logic';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import {
  apiFormsLogicCreate,
  apiFormsLogicDelete,
  apiFormsLogicList,
  apiFormsLogicRuleCreate,
  apiFormsLogicRuleDelete,
  apiFormsLogicRuleUpdate,
  apiFormsLogicUpdate,
} from '../../services/api/form-logic';
import {
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import StepSelector from '../../components/StepSelector';
import { ApiFormsGetResponse } from '../../services/api/types/forms';
import { apiFormsGetByAliasId } from '../../services/api/forms';
import ResultSelector from '../../components/ResultSelector';
import {
  INPUT_TYPES_WITH_OPTIONS,
  InputEntity,
} from '../../services/api/types/steps';
import { FormResultListResponse } from '../../services/api/types/form-result';
import { apiFormsResultList } from '../../services/api/form-result';

interface FormLogicFormValues {
  stepId: string;
  formLogicRules: (Partial<FormLogicEntity> & {
    thenGoToStepId: string | null;
    thenGoToResultId: string | null;
    rules: (Partial<FormLogicRuleEntity> & {
      previousRuleType?: NextLogicRuleType;
      inputId: string;
      optionId: string | null;
    })[];
  })[];
}

interface FormValues {
  steps: FormLogicFormValues[];
}

function FormLogicPage() {
  const { token } = useSelector(selectUserState);
  const { formId } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<FormLogicListResponse | null>(null);
  const [formData, setFormData] = useState<ApiFormsGetResponse | null>(null);
  const [response, setResponse] = useState<FormResultListResponse | null>(null);
  const [form] = useForm<FormValues>();
  const stepValues = Form.useWatch('steps', form);
  const [loading, setLoading] = useState(false);
  const [modal, deleteModalRender] = Modal.useModal();
  const [inputMap, setInputMap] = useState<Map<string, InputEntity>>(new Map());

  async function fetchData() {
    setLoading(true);
    try {
      if (token && formId) {
        const [[res, status], [formRes], [resResults]] = await Promise.all([
          apiFormsLogicList(token, { formId }),
          apiFormsGetByAliasId(token, formId),
          apiFormsResultList(token, { formId }),
        ]);
        if (status === 201 && res.logic && formRes?.data?.steps) {
          if (resResults?.results) {
            setResponse(resResults);
          }
          setData(res);
          const stepsOrdered = formRes?.data?.steps?.sort(
            (a, b) => a.order - b.order,
          );
          setFormData({
            ...formRes,
            data: {
              ...formRes.data,
              steps: stepsOrdered,
            },
          });
          const allInputs = stepsOrdered?.reduce<InputEntity[]>((acc, step) => {
            if (step.inputs) {
              acc.push(...step.inputs);
            }
            return acc;
          }, []);
          setInputMap(
            new Map<string, InputEntity>(
              allInputs.map((input) => [input.aliasId, input]),
            ),
          );

          form.resetFields();

          form.setFieldsValue({
            steps: stepsOrdered?.map((step) => ({
              stepId: step.aliasId,
              formLogicRules: res.logic
                .filter((logic) => logic.step?.aliasId === step.aliasId)
                .map((logic) => ({
                  ...logic,
                  thenGoToStepId: logic.thenGoToStep?.aliasId,
                  thenGoToResultId: logic.thenGoToResult?.aliasId,
                  rules: logic?.rules
                    ?.sort((a, b) => a.order - b.order)
                    ?.map((rule, rIndex) => ({
                      ...rule,
                      inputId: rule.input?.aliasId,
                      previousRuleType:
                        logic?.rules?.[rIndex - 1]?.nextRuleType,
                      optionId: rule.option?.aliasId,
                    }))
                    .sort((a, b) => a.order - b.order),
                })),
            })) as any,
          });
        }
      } else {
        setData(null);
        setFormData(null);
        message.error('Error fetching data');
      }
    } catch (e) {
      console.error(e);
      message.error('Error fetching data');
    } finally {
      setLoading(false);
    }
  }

  function validateFormLogicRule(
    rule: FormLogicFormValues['formLogicRules'][0]['rules'][0],
  ): 'create' | 'update' | null {
    if (!rule.inputId) {
      return null;
    }
    const inputFound = inputMap.get(rule.inputId);
    if (!inputFound) {
      message.error('Input not found');
      return null;
    }
    if (!rule.type) {
      return null;
    }
    if (
      INPUT_TYPES_WITH_OPTIONS.includes(inputFound.inputType as any) &&
      !rule.optionId
    ) {
      return null;
    }
    if (
      !INPUT_TYPES_WITH_OPTIONS.includes(inputFound.inputType as any) &&
      !rule.value
    ) {
      return null;
    }
    if (rule.aliasId) {
      return 'update';
    }
    return 'create';
  }

  function validateFormLogic(
    stepId: string,
    logicValues: FormLogicFormValues['formLogicRules'][0],
  ): 'create' | 'update' | null {
    if (!stepId) {
      return null;
    }
    if (!logicValues.type) {
      return null;
    }
    if (logicValues.type !== LogicType.ALWAYS && !logicValues.rules?.length) {
      return null;
    }
    if (!logicValues.thenType) {
      return null;
    }
    if (
      logicValues.thenType === LogicThenType.GO_TO &&
      !logicValues.thenGoToStepId &&
      !logicValues.thenGoToResultId
    ) {
      return null;
    }
    if (
      logicValues.thenType !== LogicThenType.GO_TO &&
      !logicValues.thenValue
    ) {
      return null;
    }
    if (logicValues.aliasId) {
      return 'update';
    }
    return 'create';
  }

  async function removeFormLogic(aliasId: string) {
    setLoading(true);
    try {
      if (token) {
        const [res] = await apiFormsLogicDelete(token, { ids: [aliasId] });
        if (res?.logic?.length) {
          message.success('Logic deleted');
        } else {
          message.error('Error deleting logic');
        }
      }
    } catch (e) {
      console.error(e);
      message.error('Error deleting logic');
    } finally {
      await fetchData();
      setLoading(false);
    }
  }

  async function removeFormLogicRule(aliasId: string) {
    setLoading(true);
    try {
      if (token) {
        const [res] = await apiFormsLogicRuleDelete(token, { ids: [aliasId] });
        if (res?.rules?.length) {
          message.success('Rule deleted');
        } else {
          message.error('Error deleting rule');
        }
      }
    } catch (e) {
      console.error(e);
      message.error('Error deleting rule');
    } finally {
      await fetchData();
      setLoading(false);
    }
  }

  async function handleLogicChange(
    stepId: string,
    logicValues: FormLogicFormValues['formLogicRules'][0],
  ) {
    try {
      if (token) {
        const validatedLogic = validateFormLogic(stepId, logicValues);
        if (validatedLogic === 'create') {
          const allRulesValid = logicValues.rules.every((rule) => {
            return validateFormLogicRule(rule as any) === 'create';
          });
          if (allRulesValid) {
            setLoading(true);
            const [res, status] = await apiFormsLogicCreate(token, {
              formId: formId as string,
              stepId,
              type: logicValues.type,
              thenType: logicValues.thenType,
              thenValue:
                logicValues.thenType === LogicThenType.GO_TO
                  ? null
                  : logicValues.thenValue,
              thenGoToStepId:
                logicValues.thenType === LogicThenType.GO_TO
                  ? logicValues.thenGoToStepId
                  : null,
              thenGoToResultId:
                logicValues.thenType === LogicThenType.GO_TO
                  ? logicValues.thenGoToResultId
                  : null,
              rules: logicValues.rules.map(
                (
                  rule: Partial<FormLogicRuleEntity> & {
                    previousRuleType?: NextLogicRuleType;
                    inputId: string;
                    optionId: string | null;
                  },
                ) => ({
                  inputId: rule.inputId,
                  type: rule.type,
                  optionId: rule.optionId || null,
                  value: rule.value || null,
                  previousRuleType: rule.previousRuleType,
                }),
              ),
            });
            if (status === 201 && res.logic) {
              message.success('Logic saved');
            } else {
              message.error('Error saving logic');
            }
            await fetchData();
          }
        } else if (validatedLogic === 'update') {
          // Update
          setLoading(true);
          const [res, status] = await apiFormsLogicUpdate(token, {
            formLogicId: logicValues.aliasId as string,
            type: logicValues.type,
            thenType: logicValues.thenType,
            thenValue:
              logicValues.thenType === LogicThenType.GO_TO
                ? null
                : logicValues.thenValue || null,
            thenGoToStepId:
              logicValues.thenType === LogicThenType.GO_TO
                ? logicValues.thenGoToStepId || null
                : null,
            thenGoToResultId:
              logicValues.thenType === LogicThenType.GO_TO
                ? logicValues.thenGoToResultId || null
                : null,
          });
          if (status === 201 && res.logic) {
            message.success('Logic saved');
          } else {
            message.error('Error saving logic');
          }
          await fetchData();
        }
      }
    } catch (e) {
      console.error(e);
      message.error('Error saving logic');
    } finally {
      setLoading(false);
    }
  }

  async function handleFormLogicRuleChange(
    formLogicAliasId: string,
    rule: FormLogicFormValues['formLogicRules'][0]['rules'][0],
  ) {
    if (!formLogicAliasId) {
      return;
    }
    try {
      if (token) {
        const validated = validateFormLogicRule(rule);
        if (validated === 'create') {
          setLoading(true);
          const [res, status] = await apiFormsLogicRuleCreate(token, {
            formLogicId: formLogicAliasId,
            inputId: rule.inputId,
            type: rule.type,
            optionId: rule.optionId || null,
            value: rule.value || null,
            previousRuleType: rule.previousRuleType,
          });
          if (status === 201 && res.rule) {
            message.success('Rule saved');
          } else {
            message.error('Error saving rule');
          }
          await fetchData();
        } else if (validated === 'update') {
          setLoading(true);
          const [res, status] = await apiFormsLogicRuleUpdate(token, {
            formLogicRuleId: rule.aliasId,
            type: rule.type,
            optionId: rule.optionId || null,
            value: rule.value || null,
          });
          if (status === 201 && res.rule) {
            message.success('Rule saved');
          } else {
            message.error('Error saving rule');
          }
          await fetchData();
        }
      }
    } catch (e) {
      console.error(e);
      message.error('Error saving rule');
    } finally {
      setLoading(false);
    }
  }

  async function handleFormLogicRulePreviousRuleTypeChange(
    formLogicAliasId: string,
    type: NextLogicRuleType,
    previousRule: FormLogicFormValues['formLogicRules'][0]['rules'][0],
  ) {
    if (!formLogicAliasId) {
      return;
    }
    try {
      if (token) {
        setLoading(true);
        const [res, status] = await apiFormsLogicRuleUpdate(token, {
          formLogicRuleId: previousRule.aliasId,
          nextRuleType: type,
        });
        if (status === 201 && res.rule) {
          message.success('Rule saved');
        } else {
          message.error('Error saving rule');
        }
        await fetchData();
      }
    } catch (e) {
      console.error(e);
      message.error('Error saving rule');
    } finally {
      setLoading(false);
    }
  }

  if (!formId) {
    navigate(RouterPaths.FORMS);
  }

  useEffect(() => {
    if (token && formId) {
      fetchData();
    }
  }, [token, formId]);

  const isLoading = !data || !formData || loading;

  return (
    <Card bordered={false} title="Form Logic">
      {!data || !formData ? <LoadingOutlined width={100} /> : null}
      <Form disabled={isLoading} form={form}>
        {token &&
          formData?.data?.steps?.map((step, index) => {
            const valuesForThisStep = stepValues?.[index];
            // const stepsBeforeThis = formData?.data?.steps?.slice(
            //   0,
            //   formData?.data?.steps?.length - 1,
            // );
            // From map of inputs, get all inputs that can be used in this step
            const inputsThatCanBeUsed: InputEntity[] = [];
            inputMap.forEach((input) => {
              inputsThatCanBeUsed.push(input);
            });
            if (step.inputs) {
              inputsThatCanBeUsed.push(...step.inputs);
            }
            return (
              <div
                key={step.aliasId}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '45px',
                  borderBottom: '2px solid #e8e8e8',
                }}
              >
                <div style={{ display: 'flex' }}>
                  <strong style={{ marginRight: '10px' }}>
                    Step {step.order}:
                  </strong>
                  <span style={{ marginRight: '10px' }}>{step.title}</span>
                </div>
                <Form.List name={['steps', index, 'formLogicRules']}>
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field, logicIndex) => {
                        const valuesForThisLogic =
                          valuesForThisStep?.formLogicRules?.[logicIndex];
                        return (
                          <Card
                            style={{
                              margin: '0 20px 20px',
                              background: !valuesForThisLogic?.aliasId
                                ? 'antiquewhite'
                                : 'aliceblue',
                            }}
                            key={field.key}
                          >
                            <Form.Item noStyle required={false} key={field.key}>
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  // justifyContent: 'space-between',
                                }}
                              >
                                <Form.Item
                                  name={[field.name, 'type']}
                                  style={{ marginRight: '45px' }}
                                  label={null}
                                >
                                  <Select
                                    style={{ width: '90px' }}
                                    onChange={() => {
                                      handleLogicChange(
                                        step.aliasId,
                                        form.getFieldValue([
                                          'steps',
                                          index,
                                          'formLogicRules',
                                          field.name,
                                        ]),
                                      );
                                    }}
                                  >
                                    {Object.values(LogicType).map((type) => (
                                      <Select.Option key={type} value={type}>
                                        {type}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  label="Type"
                                  name={[field.name, 'thenType']}
                                  style={{ marginRight: '15px' }}
                                >
                                  <Select
                                    style={{ width: '90px' }}
                                    placeholder="Select a type"
                                    onChange={() => {
                                      handleLogicChange(
                                        step.aliasId,
                                        form.getFieldValue([
                                          'steps',
                                          index,
                                          'formLogicRules',
                                          field.name,
                                        ]),
                                      );
                                    }}
                                  >
                                    {Object.values(LogicThenType).map(
                                      (type) => (
                                        <Select.Option key={type} value={type}>
                                          {type}
                                        </Select.Option>
                                      ),
                                    )}
                                  </Select>
                                </Form.Item>
                                {valuesForThisLogic?.thenType !==
                                LogicThenType.GO_TO ? (
                                  <Form.Item
                                    label="Value"
                                    name={[field.name, 'thenValue']}
                                  >
                                    <InputNumber
                                      onBlur={() => {
                                        handleLogicChange(
                                          step.aliasId,
                                          form.getFieldValue([
                                            'steps',
                                            index,
                                            'formLogicRules',
                                            field.name,
                                          ]),
                                        );
                                      }}
                                    />
                                  </Form.Item>
                                ) : null}
                                {valuesForThisLogic?.thenType ===
                                LogicThenType.GO_TO ? (
                                  <>
                                    <StepSelector
                                      style={{
                                        width: '400px',
                                        marginRight: '15px',
                                      }}
                                      formId={formId as string}
                                      name={[field.name, 'thenGoToStepId']}
                                      label="Step"
                                      required={false}
                                      onChange={(v) => {
                                        form.setFieldValue(
                                          [
                                            'steps',
                                            index,
                                            'formLogicRules',
                                            field.name,
                                            'thenGoToResultId',
                                          ],
                                          null,
                                        );
                                        handleLogicChange(
                                          step.aliasId,
                                          form.getFieldValue([
                                            'steps',
                                            index,
                                            'formLogicRules',
                                            field.name,
                                          ]),
                                        );
                                      }}
                                      steps={formData?.data?.steps}
                                      disabled={isLoading}
                                    />
                                    <ResultSelector
                                      style={{ width: '250px' }}
                                      formId={formId as string}
                                      name={[field.name, 'thenGoToResultId']}
                                      label="Result"
                                      required={false}
                                      onChange={(v) => {
                                        form.setFieldValue(
                                          [
                                            'steps',
                                            index,
                                            'formLogicRules',
                                            field.name,
                                            'thenGoToStepId',
                                          ],
                                          null,
                                        );
                                        handleLogicChange(
                                          step.aliasId,
                                          form.getFieldValue([
                                            'steps',
                                            index,
                                            'formLogicRules',
                                            field.name,
                                          ]),
                                        );
                                      }}
                                      results={response?.results}
                                      disabled={isLoading}
                                    />
                                  </>
                                ) : null}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-end',
                                    marginRight: '25px',
                                  }}
                                >
                                  <Button
                                    danger
                                    onClick={async () => {
                                      if (valuesForThisLogic?.aliasId) {
                                        modal.confirm({
                                          title:
                                            'Do you want to delete this logic?',
                                          onOk: async () => {
                                            await removeFormLogic(
                                              valuesForThisLogic.aliasId as string,
                                            );
                                          },
                                        });
                                      } else {
                                        remove(logicIndex);
                                      }
                                    }}
                                  >
                                    <MinusCircleOutlined />
                                    Remove Logic
                                  </Button>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                  }}
                                >
                                  <span
                                    style={{
                                      marginBottom: '15px',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Rules:
                                  </span>
                                  {valuesForThisLogic?.type === LogicType.IF ? (
                                    <Form.List name={[field.name, 'rules']}>
                                      {(
                                        fields,
                                        { add, remove },
                                        { errors },
                                      ) => (
                                        <>
                                          {fields.map(
                                            (ruleField, ruleIndex) => {
                                              const valuesForThisRule =
                                                valuesForThisLogic?.rules?.[
                                                  ruleIndex
                                                ];
                                              const inputSelected =
                                                inputsThatCanBeUsed.find(
                                                  (input) =>
                                                    input.aliasId ===
                                                    valuesForThisRule?.inputId,
                                                );
                                              const isOption =
                                                INPUT_TYPES_WITH_OPTIONS.includes(
                                                  inputSelected?.inputType as any,
                                                );
                                              const ruleIsUpdatingOrCreating =
                                                valuesForThisLogic?.aliasId
                                                  ?.length;

                                              return (
                                                <div
                                                  key={ruleField.key}
                                                  style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                  }}
                                                >
                                                  {ruleIndex > 0 &&
                                                  valuesForThisRule?.previousRuleType ? (
                                                    <div
                                                      style={{
                                                        width: '100%',
                                                        background: '#e8e8e8',
                                                        marginBottom: '15px',
                                                        justifyContent:
                                                          'center',
                                                        display: 'flex',
                                                        textTransform:
                                                          'uppercase',
                                                        fontWeight: 'bold',
                                                        padding: '4px',
                                                      }}
                                                    >
                                                      <Form.Item
                                                        name={[
                                                          ruleField.name,
                                                          'previousRuleType',
                                                        ]}
                                                        style={{
                                                          marginRight: '15px',
                                                          marginBottom: 0,
                                                        }}
                                                        label={null}
                                                      >
                                                        <Select
                                                          style={{
                                                            width: '90px',
                                                            margin: 0,
                                                          }}
                                                          onChange={() => {
                                                            const previousRule =
                                                              form.getFieldValue(
                                                                [
                                                                  'steps',
                                                                  index,
                                                                  'formLogicRules',
                                                                  field.name,
                                                                  'rules',
                                                                  ruleIndex - 1,
                                                                ] as any,
                                                              );
                                                            if (
                                                              ruleIsUpdatingOrCreating &&
                                                              previousRule &&
                                                              valuesForThisRule?.aliasId
                                                            ) {
                                                              handleFormLogicRulePreviousRuleTypeChange(
                                                                valuesForThisLogic?.aliasId as string,
                                                                form.getFieldValue(
                                                                  [
                                                                    'steps',
                                                                    index,
                                                                    'formLogicRules',
                                                                    field.name,
                                                                    'rules',
                                                                    ruleIndex,
                                                                    'previousRuleType',
                                                                  ] as any,
                                                                ),
                                                                previousRule,
                                                              );
                                                            }
                                                          }}
                                                        >
                                                          {Object.values(
                                                            NextLogicRuleType,
                                                          ).map((type) => (
                                                            <Select.Option
                                                              key={type}
                                                              value={type}
                                                            >
                                                              {type}
                                                            </Select.Option>
                                                          ))}
                                                        </Select>
                                                      </Form.Item>
                                                    </div>
                                                  ) : null}
                                                  <Card
                                                    style={{
                                                      marginBottom: '15px',
                                                      background:
                                                        !valuesForThisRule?.aliasId &&
                                                        ruleIsUpdatingOrCreating
                                                          ? 'antiquewhite'
                                                          : undefined,
                                                    }}
                                                  >
                                                    <Form.Item
                                                      noStyle
                                                      required={false}
                                                      key={ruleField.key}
                                                    >
                                                      <div
                                                        style={{
                                                          width: '100%',
                                                          display: 'flex',
                                                          justifyContent:
                                                            'space-between',
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                          }}
                                                        >
                                                          <Form.Item
                                                            name={[
                                                              ruleField.name,
                                                              'inputId',
                                                            ]}
                                                            style={{
                                                              marginRight:
                                                                '15px',
                                                              marginBottom: 0,
                                                            }}
                                                            label={null}
                                                          >
                                                            <Select
                                                              style={{
                                                                width: '450px',
                                                              }}
                                                              onChange={() => {
                                                                if (
                                                                  ruleIsUpdatingOrCreating
                                                                ) {
                                                                  handleFormLogicRuleChange(
                                                                    valuesForThisLogic?.aliasId as string,
                                                                    form.getFieldValue(
                                                                      [
                                                                        'steps',
                                                                        index,
                                                                        'formLogicRules',
                                                                        field.name,
                                                                        'rules',
                                                                        ruleField.name,
                                                                      ] as any,
                                                                    ),
                                                                  );
                                                                } else {
                                                                  handleLogicChange(
                                                                    step.aliasId,
                                                                    form.getFieldValue(
                                                                      [
                                                                        'steps',
                                                                        index,
                                                                        'formLogicRules',
                                                                        field.name,
                                                                      ],
                                                                    ),
                                                                  );
                                                                }
                                                              }}
                                                            >
                                                              {inputsThatCanBeUsed.map(
                                                                (input) => (
                                                                  <Select.Option
                                                                    key={
                                                                      input.aliasId
                                                                    }
                                                                    value={
                                                                      input.aliasId
                                                                    }
                                                                  >
                                                                    [
                                                                    {
                                                                      input.inputType
                                                                    }
                                                                    ]:{' '}
                                                                    {
                                                                      input.question
                                                                    }
                                                                  </Select.Option>
                                                                ),
                                                              )}
                                                            </Select>
                                                          </Form.Item>
                                                          <Form.Item
                                                            name={[
                                                              ruleField.name,
                                                              'type',
                                                            ]}
                                                            style={{
                                                              marginRight:
                                                                '45px',
                                                              marginBottom: 0,
                                                            }}
                                                            label={null}
                                                          >
                                                            <Select
                                                              style={{
                                                                width: '90px',
                                                              }}
                                                              onChange={() => {
                                                                if (
                                                                  ruleIsUpdatingOrCreating
                                                                ) {
                                                                  handleFormLogicRuleChange(
                                                                    valuesForThisLogic?.aliasId as string,
                                                                    form.getFieldValue(
                                                                      [
                                                                        'steps',
                                                                        index,
                                                                        'formLogicRules',
                                                                        field.name,
                                                                        'rules',
                                                                        ruleField.name,
                                                                      ] as any,
                                                                    ),
                                                                  );
                                                                } else {
                                                                  handleLogicChange(
                                                                    step.aliasId,
                                                                    form.getFieldValue(
                                                                      [
                                                                        'steps',
                                                                        index,
                                                                        'formLogicRules',
                                                                        field.name,
                                                                      ],
                                                                    ),
                                                                  );
                                                                }
                                                              }}
                                                            >
                                                              {Object.values(
                                                                LogicRuleType,
                                                              ).map((type) => (
                                                                <Select.Option
                                                                  key={type}
                                                                  value={type}
                                                                >
                                                                  {type}
                                                                </Select.Option>
                                                              ))}
                                                            </Select>
                                                          </Form.Item>
                                                          {isOption ? (
                                                            <Form.Item
                                                              name={[
                                                                ruleField.name,
                                                                'optionId',
                                                              ]}
                                                              style={{
                                                                marginRight:
                                                                  '15px',
                                                                marginBottom: 0,
                                                              }}
                                                              label={'Option'}
                                                            >
                                                              <Select
                                                                style={{
                                                                  width:
                                                                    '350px',
                                                                }}
                                                                onChange={() => {
                                                                  if (
                                                                    ruleIsUpdatingOrCreating
                                                                  ) {
                                                                    handleFormLogicRuleChange(
                                                                      valuesForThisLogic?.aliasId as string,
                                                                      form.getFieldValue(
                                                                        [
                                                                          'steps',
                                                                          index,
                                                                          'formLogicRules',
                                                                          field.name,
                                                                          'rules',
                                                                          ruleField.name,
                                                                        ] as any,
                                                                      ),
                                                                    );
                                                                  } else {
                                                                    handleLogicChange(
                                                                      step.aliasId,
                                                                      form.getFieldValue(
                                                                        [
                                                                          'steps',
                                                                          index,
                                                                          'formLogicRules',
                                                                          field.name,
                                                                        ],
                                                                      ),
                                                                    );
                                                                  }
                                                                }}
                                                              >
                                                                {inputSelected?.options?.map(
                                                                  (option) => (
                                                                    <Select.Option
                                                                      key={
                                                                        option.aliasId
                                                                      }
                                                                      value={
                                                                        option.aliasId
                                                                      }
                                                                    >
                                                                      {
                                                                        option.value
                                                                      }
                                                                    </Select.Option>
                                                                  ),
                                                                )}
                                                              </Select>
                                                            </Form.Item>
                                                          ) : (
                                                            <Form.Item
                                                              name={[
                                                                ruleField.name,
                                                                'value',
                                                              ]}
                                                              style={{
                                                                marginRight:
                                                                  '15px',
                                                                marginBottom: 0,
                                                              }}
                                                              label={'Value'}
                                                            >
                                                              <Input
                                                                style={{
                                                                  width:
                                                                    '350px',
                                                                }}
                                                                onBlur={() => {
                                                                  if (
                                                                    ruleIsUpdatingOrCreating
                                                                  ) {
                                                                    handleFormLogicRuleChange(
                                                                      valuesForThisLogic?.aliasId as string,
                                                                      form.getFieldValue(
                                                                        [
                                                                          'steps',
                                                                          index,
                                                                          'formLogicRules',
                                                                          field.name,
                                                                          'rules',
                                                                          ruleField.name,
                                                                        ] as any,
                                                                      ),
                                                                    );
                                                                  } else {
                                                                    handleLogicChange(
                                                                      step.aliasId,
                                                                      form.getFieldValue(
                                                                        [
                                                                          'steps',
                                                                          index,
                                                                          'formLogicRules',
                                                                          field.name,
                                                                        ],
                                                                      ),
                                                                    );
                                                                  }
                                                                }}
                                                              />
                                                            </Form.Item>
                                                          )}
                                                        </div>
                                                        <Button
                                                          danger
                                                          onClick={async () => {
                                                            if (
                                                              valuesForThisRule?.aliasId
                                                            ) {
                                                              modal.confirm({
                                                                title:
                                                                  'Do you want to delete this rule?',
                                                                onOk: async () => {
                                                                  await removeFormLogicRule(
                                                                    valuesForThisRule?.aliasId,
                                                                  );
                                                                },
                                                              });
                                                            } else {
                                                              remove(ruleIndex);
                                                            }
                                                          }}
                                                          icon={
                                                            <MinusCircleOutlined />
                                                          }
                                                        />
                                                      </div>
                                                    </Form.Item>
                                                  </Card>
                                                </div>
                                              );
                                            },
                                          )}
                                          <div style={{ display: 'flex' }}>
                                            {valuesForThisLogic?.rules
                                              ?.length ? (
                                              <>
                                                <Button
                                                  type="dashed"
                                                  onClick={() => {
                                                    add({
                                                      previousRuleType:
                                                        NextLogicRuleType.OR,
                                                    });
                                                  }}
                                                  style={{ width: '60%' }}
                                                  icon={<PlusOutlined />}
                                                >
                                                  OR
                                                </Button>
                                                <Button
                                                  type="dashed"
                                                  onClick={() => {
                                                    add({
                                                      previousRuleType:
                                                        NextLogicRuleType.AND,
                                                    });
                                                  }}
                                                  style={{ width: '60%' }}
                                                  icon={<PlusOutlined />}
                                                >
                                                  AND
                                                </Button>
                                              </>
                                            ) : (
                                              <Button
                                                type="dashed"
                                                onClick={() => {
                                                  add({});
                                                }}
                                                style={{ width: '60%' }}
                                                icon={<PlusOutlined />}
                                              >
                                                Add Rule
                                              </Button>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </Form.List>
                                  ) : null}
                                </div>
                              </div>
                            </Form.Item>
                          </Card>
                        );
                      })}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add({
                              thenGoToStepId: null,
                              thenGoToResultId: null,
                              rules: [],
                              type: LogicType.IF,
                              thenType: LogicThenType.GO_TO,
                            });
                          }}
                          style={{ width: '60%' }}
                          icon={<PlusOutlined />}
                        >
                          Add Logic
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </div>
            );
          })}
      </Form>
      {deleteModalRender}
    </Card>
  );
}

export default FormLogicPage;
