import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { RouterPaths } from '../../router/paths';
import { Button, Card, Form, Input, InputNumber, message, Select } from 'antd';
import { FormResultType } from '../../services/api/types/form-result';
import { useForm } from 'antd/es/form/Form';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import {
  apiFormsResultCreate,
  apiFormsResultDelete,
  apiFormsResultGet,
  apiFormsResultUpdate,
} from '../../services/api/form-result';
import ImageInput from '../../components/ImageInput/imageInput';
import { apiFormsGet, getOwnerOfTheForm } from '../../services/api/forms';
import { FormEntity } from '../../services/api/types/forms';
import { LengthConstants } from '../../services/api/types';

interface FormResultPageProps {
  create?: boolean;
}

interface FormValues {
  type: FormResultType;
  title: string | null;
  description: string | null;
  price: number | null;
  redirectUrl: string | null;
  buttonText: string | null;
  priceLabel: string;
  priceDisclaimer: string;
  imageId: string;
}

function FormResultPage({ create = true }: FormResultPageProps) {
  const { formId, formResultId } = useParams();
  const { token } = useSelector(selectUserState);
  const navigate = useNavigate();
  const [form] = useForm<FormValues>();
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState<FormEntity | null>(null);

  async function fetchFormData(formId: number) {
    if (token) {
      setLoading(true);
      try {
        const [formRes] = await apiFormsGet(token, formId);

        if (formRes?.data) {
          setFormData(formRes.data);
        } else {
          message.error('Form not found');
          navigate(RouterPaths.USERS);
        }
      } catch (e) {
        message.error('Error fetching form');
        navigate(RouterPaths.USERS);
      } finally {
        setLoading(false);
      }
    }
  }

  async function fetchData() {
    if (token && formResultId) {
      setLoading(true);
      try {
        const [res] = await apiFormsResultGet(token, { formResultId });

        if (res?.result) {
          form.resetFields();
          form.setFieldsValue({
            type: res.result.type,
            title: res.result.title,
            description: res.result.description,
            price: res.result.price,
            redirectUrl: res.result.redirectUrl,
            buttonText: res.result.buttonText,
            priceLabel: res.result.priceLabel,
            priceDisclaimer: res.result.priceDisclaimer,
            imageId: res.result.image?.aliasId,
          });
          await fetchFormData(res.result.form?.id as number);
        } else {
          message.error('Result not found');
          navigate(RouterPaths.USERS);
        }
      } catch (e) {
        message.error('Error fetching result');
        navigate(RouterPaths.USERS);
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    if (token) {
      if (create) {
        if (!formId) {
          message.error('Form ID is required');
          navigate(RouterPaths.USERS);
        }
        fetchFormData(parseInt(formId as string, 10));
      } else {
        if (!formResultId) {
          message.error('Form Result ID is required');
          navigate(RouterPaths.USERS);
          return;
        }
        fetchData();
      }
    }
  }, [formId, formResultId, token]);

  async function handleDelete() {
    if (token && formResultId && formData) {
      try {
        setLoading(true);
        const [res] = await apiFormsResultDelete(token, {
          ids: [formResultId],
        });
        if (res?.results) {
          message.success('Result deleted');
          navigate(`${RouterPaths.FORMS}/${formData?.id}`);
        } else {
          message.error('Error deleting result');
        }
      } catch (e) {
        console.error(e);
        message.error('Error deleting result');
      } finally {
        setLoading(false);
      }
    }
  }

  async function handleFinish(values: FormValues) {
    console.log(values);
    if (token && formData?.aliasId) {
      if (create) {
        try {
          setLoading(true);

          const [res] = await apiFormsResultCreate(
            token,
            values.type === FormResultType.REDIRECT
              ? {
                  formId: formData.aliasId,
                  type: values.type,
                  redirectUrl: values.redirectUrl,
                }
              : {
                  formId: formData.aliasId,
                  type: values.type,
                  title: values.title || null,
                  description: values.description || null,
                  price: values.price || null,
                  redirectUrl: values.redirectUrl || null,
                  buttonText: values.buttonText || null,
                  priceLabel: values.priceLabel || undefined,
                  priceDisclaimer: values.priceDisclaimer || undefined,
                  imageId: values.imageId || null,
                },
          );

          if (res?.result) {
            message.success('Result created');
            navigate(`${RouterPaths.FORMS}/${formData.id}`);
          } else {
            message.error('Error creating result');
          }
        } catch (e) {
          console.error(e);
          message.error('Error creating result');
        } finally {
          setLoading(false);
        }
      } else if (formResultId) {
        try {
          setLoading(true);
          const [res] = await apiFormsResultUpdate(token, {
            formResultId,
            type: values.type,
            title: values.title || null,
            description: values.description || null,
            price: values.price || null,
            redirectUrl: values.redirectUrl || null,
            buttonText: values.buttonText || null,
            priceLabel: values.priceLabel || undefined,
            priceDisclaimer: values.priceDisclaimer || undefined,
            imageId: values.imageId || null,
          });

          if (res?.result) {
            message.success('Result updated');
          } else {
            message.error('Error updating result');
          }

          await fetchData();
        } catch (e) {
          console.error(e);
          message.error('Error updating result');
        } finally {
          setLoading(false);
        }
      }
    }
  }

  const type: FormResultType | null = Form.useWatch('type', form);

  const isDisabled = loading || !token;

  const titleVisible = type === FormResultType.SCREEN;
  const descriptionVisible = type === FormResultType.SCREEN;
  const priceVisible = type === FormResultType.SCREEN;
  const priceLabelVisible = type === FormResultType.SCREEN;
  const priceDisclaimerVisible = type === FormResultType.SCREEN;
  const buttonTextVisible = type === FormResultType.SCREEN;
  const imageVisible = type === FormResultType.SCREEN;

  return (
    <Card bordered={false} title={create ? 'Create Result' : 'Single Result'}>
      <Form form={form} disabled={isDisabled} onFinish={handleFinish}>
        <Form.Item
          name="type"
          label="Type"
          required
          rules={[{ required: true, message: 'Type is required' }]}
        >
          <Select>
            {Object.values(FormResultType).map((value) => (
              <Select.Option key={value} value={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {titleVisible ? (
          <Form.Item
            name="title"
            label="Title"
            required={type === FormResultType.SCREEN}
            rules={
              type === FormResultType.SCREEN
                ? [
                    { required: true, message: 'Title is required' },
                    {
                      type: 'string',
                      max: LengthConstants.RESULT_SCREEN_TITTLE,
                      message: `Title must be less than ${LengthConstants.RESULT_SCREEN_TITTLE} characters`,
                    },
                  ]
                : [
                    {
                      type: 'string',
                      max: LengthConstants.RESULT_SCREEN_TITTLE,
                      message: `Title must be less than ${LengthConstants.RESULT_SCREEN_TITTLE} characters`,
                    },
                  ]
            }
          >
            <Input />
          </Form.Item>
        ) : null}
        {descriptionVisible ? (
          <Form.Item
            name="description"
            label="Description"
            required={false}
            rules={[
              {
                type: 'string',
                max: LengthConstants.RESULT_SCREEN_DESCRIPTION,
                message: `Description must be less than ${LengthConstants.RESULT_SCREEN_DESCRIPTION} characters`,
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
        ) : null}
        {priceVisible ? (
          <Form.Item name="price" label="Price" required={false}>
            <InputNumber />
          </Form.Item>
        ) : null}
        <Form.Item
          name="redirectUrl"
          label="Redirect URL"
          required={type === FormResultType.REDIRECT}
          rules={
            type === FormResultType.REDIRECT
              ? [
                  { required: true, message: 'Redirect is required' },
                  {
                    type: 'string',
                    max: LengthConstants.URL,
                    message: `Redirect URL must be less than ${LengthConstants.URL} characters`,
                  },
                ]
              : [
                  {
                    type: 'string',
                    max: LengthConstants.URL,
                    message: `Redirect URL must be less than ${LengthConstants.URL} characters`,
                  },
                ]
          }
        >
          <Input placeholder="https://google.com/" />
        </Form.Item>
        {buttonTextVisible ? (
          <Form.Item
            name="buttonText"
            label="Button Text"
            required={false}
            rules={[
              {
                type: 'string',
                max: LengthConstants.RESULT_SCREEN_BUTTON_TEXT,
                message: `Button Text must be less than ${LengthConstants.RESULT_SCREEN_BUTTON_TEXT} characters`,
              },
            ]}
          >
            <Input />
          </Form.Item>
        ) : null}
        {priceLabelVisible ? (
          <Form.Item
            name="priceLabel"
            label="Price Label"
            required={true}
            initialValue={'Starting from'}
            rules={[
              {
                type: 'string',
                max: LengthConstants.RESULT_SCREEN_PRICE_LABEL,
                message: `Price Label must be less than ${LengthConstants.RESULT_SCREEN_PRICE_LABEL} characters`,
              },
              {
                required: true,
                message: 'Price Label is required',
              },
            ]}
          >
            <Input />
          </Form.Item>
        ) : null}
        {priceDisclaimerVisible ? (
          <Form.Item
            name="priceDisclaimer"
            label="Price Disclaimer"
            required={true}
            initialValue={
              'This is informative price and can be changed based on currency exchange rate.'
            }
            rules={[
              {
                type: 'string',
                max: LengthConstants.RESULT_SCREEN_PRICE_DISCLAIMER,
                message: `Price Disclaimer must be less than ${LengthConstants.RESULT_SCREEN_PRICE_DISCLAIMER} characters`,
              },
              {
                required: true,
                message: 'Price Disclaimer is required',
              },
            ]}
          >
            <Input />
          </Form.Item>
        ) : null}
        {imageVisible ? (
          <ImageInput
            name="imageId"
            label="Image"
            userId={getOwnerOfTheForm(formData)?.id}
            useAliasId
            required={false}
            allowClear
          />
        ) : null}
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={isDisabled}>
            {create ? 'Create' : 'Update'}
          </Button>
          {!create ? (
            <Button danger htmlType="button" onClick={handleDelete}>
              Delete
            </Button>
          ) : null}
        </Form.Item>
      </Form>
    </Card>
  );
}

export default FormResultPage;
