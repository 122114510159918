import { Form, FormItemProps, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { selectUserState } from '../../store/selectors/user';
import { useSelector } from 'react-redux';
import { DefaultOptionType } from 'antd/es/select';
import {
  FormResultEntity,
  FormResultListResponse,
} from '../../services/api/types/form-result';
import { apiFormsResultList } from '../../services/api/form-result';

interface ResultSelectorProps {
  formId: string;
  style?: React.CSSProperties;
  name: FormItemProps['name'];
  label?: FormItemProps['label'];
  disabled?: boolean;
  required?: boolean;
  initialValue?: string;
  onChange?: (
    value: any,
    option: DefaultOptionType | DefaultOptionType[],
  ) => void;
  results?: FormResultEntity[];
}

function ResultSelector({
  name,
  label,
  disabled,
  style,
  formId,
  required = true,
  initialValue,
  onChange,
  results,
}: ResultSelectorProps) {
  const { token } = useSelector(selectUserState);
  const [response, setResponse] = useState<FormResultListResponse | null>(null);

  async function loadData() {
    if (token && formId) {
      const [res] = await apiFormsResultList(token, { formId });

      if (res?.results) {
        setResponse(res);
      }
    }
  }

  useEffect(() => {
    if (!results) {
      loadData();
    }
  }, [formId, token, results]);

  const toUse = results ?? response?.results ?? [];

  return (
    <Form.Item
      name={name}
      label={label}
      style={style}
      rules={[{ required, message: 'Please select a result!' }]}
      initialValue={initialValue}
    >
      <Select
        disabled={disabled || (response === null && !results)}
        options={toUse.map<DefaultOptionType>((result) => ({
          value: result.aliasId,
          label: `(${result.type.slice(0, 1).toUpperCase()}) ${
            result.title ?? result.redirectUrl ?? result.aliasId
          }`,
        }))}
        onChange={onChange}
      />
    </Form.Item>
  );
}

export default ResultSelector;
