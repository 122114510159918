import { Button, Card, Form, Input, message, Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import ImageInput from '../../components/ImageInput/imageInput';
import { RouterPaths } from '../../router/paths';
import {
  apiOptionsDelete,
  apiOptionsGet,
  apiOptionsUpdate,
} from '../../services/api/options';
import {
  ApiOptionsGetResponse,
  ApiOptionsUpdateRequestBody,
} from '../../services/api/types/options';
import { selectUserState } from '../../store/selectors/user';
import {
  DesignType,
  INPUT_TYPES_WITH_OPTIONS,
} from '../../services/api/types/steps';
import { WorkspaceUserRole } from '../../services/api/types/forms';

export interface OptionEditFormState {
  value?: string;
  order?: number;
  imageId?: number;
}

function OptionEdit() {
  const navigate = useNavigate();
  const { optionId } = useParams();
  const { token } = useSelector(selectUserState);
  const [optionEditForm] = Form.useForm<OptionEditFormState>();
  const [responseGetOption, setResponseGetOption] =
    React.useState<ApiOptionsGetResponse | null>(null);

  async function loadOptionData(tk: string, oid: number) {
    const [response, status] = await apiOptionsGet(tk, oid);
    if (status !== 200) {
      message.error({ content: 'No option found!' });
    } else {
      setResponseGetOption(response);
      setTimeout(() => {
        optionEditForm.setFieldValue('value', response.data.value);
        optionEditForm.setFieldValue('order', response.data.order);
        if (
          INPUT_TYPES_WITH_OPTIONS.includes(
            response.data?.input?.inputType as any,
          )
        ) {
          optionEditForm.setFieldValue(
            'imageId',
            response.data.image ? response.data.image.id : null,
          );
        }
      }, 0);
    }
  }

  const userEditSubmitCallback = React.useCallback(
    async (values: OptionEditFormState) => {
      if (responseGetOption && token) {
        const updateBodyRequest: ApiOptionsUpdateRequestBody = {
          optionId: responseGetOption.data.id,
        };
        let shouldUpdate = false;
        if (values.value !== responseGetOption.data.value) {
          updateBodyRequest.value = values.value;
          shouldUpdate = true;
        }
        if (
          parseInt(values.order as unknown as string, 10) !==
          responseGetOption.data.order
        ) {
          updateBodyRequest.order = parseInt(
            values.order as unknown as string,
            10,
          );
          shouldUpdate = true;
        }
        if (values.imageId !== responseGetOption.data.image?.id) {
          updateBodyRequest.imageId = values.imageId || null;
          shouldUpdate = true;
        }

        if (shouldUpdate) {
          const [response, status] = await apiOptionsUpdate(
            token,
            updateBodyRequest,
          );
          if (status === 200) {
            message.success({ content: 'Option updated successfully!' });
            loadOptionData(token, response.data.id);
          } else {
            message.error({
              content: (response as any)?.message || 'Failed user update!',
            });
          }
        }
      }
    },
    [responseGetOption, token],
  );

  React.useEffect(() => {
    if (token && optionId) {
      loadOptionData(token, parseInt(optionId, 10));
    }
  }, [optionId, token]);

  if (!optionId) {
    message.error('Missing optionId in url!');
    navigate('/');
    return null;
  }

  function getOwnerUserFromResponse(res: ApiOptionsGetResponse) {
    if (res?.data?.input?.step?.form?.workspace?.team) {
      return res.data?.input?.step?.form?.workspace?.team?.find(
        (team) => team?.user && team?.role === WorkspaceUserRole.OWNER,
      )?.user;
    }
    return null;
  }

  return (
    <Card bordered={false} title="Single Option">
      <Typography.Title level={2}>Option Edit</Typography.Title>
      {responseGetOption ? (
        <>
          <Form form={optionEditForm} onFinish={userEditSubmitCallback}>
            <Form.Item
              label="Value"
              name="value"
              rules={[
                {
                  required: true,
                  message: 'Please input option value!',
                  max: 512,
                },
              ]}
              initialValue={responseGetOption.data.value}
            >
              <Input placeholder="Yes" />
            </Form.Item>
            <Form.Item
              label="Order"
              name="order"
              rules={[
                {
                  required: true,
                  message: 'Please input option order!',
                },
              ]}
              initialValue={responseGetOption.data.order}
            >
              <Input type="number" />
            </Form.Item>
            {INPUT_TYPES_WITH_OPTIONS.includes(
              responseGetOption?.data?.input?.inputType as any,
            ) ? (
              <ImageInput
                name="imageId"
                label="Option Image"
                userId={getOwnerUserFromResponse(responseGetOption)?.id}
                defaultImageId={responseGetOption.data.image?.id}
                required={
                  responseGetOption?.data?.input?.designType ===
                  DesignType.CARDS
                }
              />
            ) : null}
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update Option
              </Button>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            style={{ marginBottom: '45px' }}
            danger
            onClick={async () => {
              if (token && responseGetOption.data?.input?.step?.id) {
                const [, status] = await apiOptionsDelete(
                  token,
                  responseGetOption.data.id,
                );
                if (status !== 200) {
                  message.error('Failed to delete option!');
                } else {
                  message.success('Successfully deleted option!');
                  navigate(
                    `${RouterPaths.STEPS}/${responseGetOption.data?.input?.step?.id}`,
                  );
                }
              }
            }}
          >
            Delete Option
          </Button>
        </>
      ) : (
        'Loading'
      )}
    </Card>
  );
}

export default OptionEdit;
