import { FormLogicEntity } from './form-logic';
import { FormEntity } from './forms';
import { ImageEntity } from './images';
import { StepEntity } from './steps';

export enum FormResultType {
  REDIRECT = 'redirect',
  SCREEN = 'screen',
}

export interface FormResultEntity {
  id: number;
  aliasId: string;
  createdAt: string;
  modifiedAt: string;
  deletedAt: string | null;
  type: FormResultType;
  title: string | null;
  description: string | null;
  price: number | null;
  redirectUrl: string | null;
  buttonText: string | null;
  priceLabel: string;
  priceDisclaimer: string;
  image?: ImageEntity;
  form?: FormEntity;
  thenGoToRelations?: FormLogicEntity[];
  alwaysGoToRelations?: StepEntity[];
}

export interface FormResultCreateRequest {
  formId: string;
  type?: FormResultType;
  title?: string | null;
  description?: string | null;
  price?: number | null;
  redirectUrl?: string | null;
  buttonText?: string | null;
  priceLabel?: string;
  priceDisclaimer?: string;
  imageId?: string | null;
}

export interface FormResultCreateResponse {
  result: FormResultEntity;
}

export interface FormResultListRequest {
  formId: string;
}

export interface FormResultListResponse {
  results: FormResultEntity[];
}

export interface FormResultUpdateRequest {
  formResultId: string;
  type?: FormResultType;
  title?: string | null;
  description?: string | null;
  price?: number | null;
  redirectUrl?: string | null;
  buttonText?: string | null;
  priceLabel?: string;
  priceDisclaimer?: string;
  imageId?: string | null;
}

export interface FormResultUpdateResponse {
  result: FormResultEntity;
}

export interface FormResultDeleteRequest {
  ids: string[];
}

export interface FormResultDeleteResponse {
  results: FormResultEntity[];
}

export interface FormResultGetRequest {
  formResultId: string;
}

export interface FormResultGetResponse {
  result: FormResultEntity;
}
